//AHW login and register popup

jQuery(document).ready(function ($) {
  var $form_modal = $('.user-modal'),
    $form_login = $form_modal.find('#login'),
    $form_signup = $form_modal.find('#signup'),
    $form_forgot_password = $form_modal.find('#reset-password'),
    $form_modal_tab = $('.switcher'),
    $tab_login = $form_modal_tab.children('li').eq(0).children('a'),
    $tab_signup = $form_modal_tab.children('li').eq(1).children('a'),
    $forgot_password_link = $form_login.find('.reset-form'),
    $back_to_login_link = $form_forgot_password.find('.form-bottom-message1 a'),
    $main_nav = $('.unregistered');
  //open modal
  $main_nav.on('click', function (event) {

    if ( event.originalEvent === undefined ) {
        event.preventDefault();

        if ($(event.target).is($main_nav)) {
            // on mobile open the submenu
            $(this).children('ul').toggleClass('is-visible');
          } else {
            // on mobile close submenu
            $main_nav.children('ul').removeClass('is-visible');
            //show modal layer
            $form_modal.addClass('is-visible');
            //show the selected form
            $(event.target).is('.signup') ? signup_selected() : login_selected();
          }
    }
  });

  //close modal
  $('.user-modal').on('click', function (event) {
    if ($(event.target).is($form_modal) || $(event.target).is('.close-form')) {
      $form_modal.removeClass('is-visible');
    }
  });
  //close modal when clicking the esc keyboard button
  $(document).keyup(function (event) {
    if (event.which == '27') {
      $form_modal.removeClass('is-visible');
    }
  });

  //switch from a tab to another
  $form_modal_tab.on('click', function (event) {
    event.preventDefault();
    ($(event.target).is($tab_login)) ? login_selected() : signup_selected();
  });

  //hide or show password
  $('.hide-password').on('click', function () {
    var $this = $(this),
      $password_field = $this.prev('input');

    ('password' == $password_field.attr('type')) ? $password_field.attr('type', 'text') : $password_field.attr('type', 'password');
    ('اخفاء' == $this.text()) ? $this.text('اظهار') : $this.text('اخفاء');
    //focus and move cursor to the end of input field
    $password_field.putCursorAtEnd();
  });

  //show forgot-password form
  $forgot_password_link.on('click', function (event) {
    event.preventDefault();
    $tab_login.removeClass('selected');
    $tab_signup.removeClass('selected');
    forgot_password_selected();
  });

  //back to login from the forgot-password form
  $back_to_login_link.on('click', function (event) {
    event.preventDefault();
    login_selected();
  });

  function login_selected() {
    $form_login.addClass('is-selected');
    $form_signup.removeClass('is-selected');
    $form_forgot_password.removeClass('is-selected');
    $tab_login.addClass('selected');
    $tab_signup.removeClass('selected');
  }

  function signup_selected() {
    $form_login.removeClass('is-selected');
    $form_signup.addClass('is-selected');
    $form_forgot_password.removeClass('is-selected');
    $tab_login.removeClass('selected');
    $tab_signup.addClass('selected');
  }

  function forgot_password_selected() {
    $form_login.removeClass('is-selected');
    $form_signup.removeClass('is-selected');
    $form_forgot_password.addClass('is-selected');
  }

  //REMOVE THIS - it's just to show error messages
  // $form_login.find('input[type="submit"]').on('click', function(event){
  //   event.preventDefault();
  //   $form_login.find('input[type="email"]').toggleClass('has-error').next('span').toggleClass('is-visible');
  // });
  // $form_signup.find('input[type="submit"]').on('click', function(event){
  //   event.preventDefault();
  //   $form_signup.find('input[type="email"]').toggleClass('has-error').next('span').toggleClass('is-visible');
  // });


});


//credits https://css-tricks.com/snippets/jquery/move-cursor-to-end-of-textarea-or-input/
jQuery.fn.putCursorAtEnd = function () {
  return this.each(function () {
    // If this function exists...
    if (this.setSelectionRange) {
      // ... then use it (Doesn't work in IE)
      // Double the length because Opera is inconsistent about whether a carriage return is one character or two. Sigh.
      var len = $(this).val().length * 2;
      this.setSelectionRange(len, len);
    } else {
      // ... otherwise replace the contents with itself
      // (Doesn't work in Google Chrome)
      $(this).val($(this).val());
    }
  });
};

jQuery('#cody-info ul li').eq(1).on('click', function () {
  $('#cody-info').hide();
});

$(document).on('focus', '.has-error', function () {
  $(this).prevAll('.error-message').remove()
})

function recaptcha_v3($action) {
  let $token = null;
  grecaptcha.execute('6LcICEgmAAAAABQojQ8K4ZyQz3hgC3DyQf_mrxsl', {
    action: $action
  }).
    then(function (token) {
      $token = token;
    });
  return $token;
}



$('#login-form').on('submit', function (e) {
  e.preventDefault();
  var data = $(this).serializeArray();
  var errors = false;
  var dt = {};
  $('#login input').removeClass('has-error');
  data.forEach(function (e, i) {
    dt[e.name] = e.value;
    if (e.value == '' && e.name != 'frecaptcha') {
      errors = true;
      $('#login input[name=' + e.name + ']').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">هذا الحقل مطلوب.</span>')
    }
  })
  if (errors == true) {
    return false;
  }
  $.ajax({
    url: '/login',
    type: 'POST',
    data: {
      ...dt
    },
    beforeSend: () => {
      $(this).find('input[type=submit]').attr('disabled', true).val('').addClass('loading-white')
    },
    error: (jqXHR, textStatus, errorThrown) => {
      $(this).find('input[type=submit]').attr('disabled', false).val('دخول').removeClass('loading-white')
    },
    success: (data) => {
      if (data.success) {
        location.reload();
      } else {
        $('#login input[name=email]').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">' + data.message + '</span>')

      }
      $(this).find('input[type=submit]').attr('disabled', false).val('دخول').removeClass('loading-white')
    }
  })

})



$('#register-form').on('submit', function (e) {
  e.preventDefault();
  var data = $(this).serializeArray();
  var errors = false;
  var dt = {};
  $('#register-form input').removeClass('has-error');
  data.forEach(function (e, i) {
    dt[e.name] = e.value;
    if (e.value == '' && e.name != 'frecaptcha') {
      errors = true;
      $('#register-form input[name=' + e.name + ']').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">هذا الحقل مطلوب.</span>')
    }
  })
  if (errors == true) {
    return false;
  }
  $.ajax({
    url: '/register',
    type: 'POST',
    data: {
      ...dt
    },
    beforeSend: () => {
      $(this).find('input[type=submit]').attr('disabled', true).val('').addClass('loading-white')
    },
    error: (jqXHR, textStatus, errorThrown) => {
      $(this).find('input[type=submit]').attr('disabled', false).val('تسجيل').removeClass('loading-white')
    },
    success: (data) => {
      if (data.success) {
        location.reload();
      } else {
        if (data.message) {
          $('#register-form input[name=fname]').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">' + data.message + '</span>')
        }

        if (data.errors) {
          $('#register-form .fieldset .error-message').remove();
          $.each(data.errors, function (e, i) {
            $('#register-form input[name=' + e + ']').addClass('has-error').closest('.fieldset')
              .prepend('<span class="error-message">' + i[0] + '</span>')
          });
        }
      }
      $(this).find('input[type=submit]').attr('disabled', false).val('تسجيل').removeClass('loading-white')
    }
  })

})


$('#recover-form').on('submit', function (e) {
  e.preventDefault();
  var data = $(this).serializeArray();
  var errors = false;
  var dt = {};
  $('#recover-form input').removeClass('has-error');
  data.forEach(function (e, i) {
    dt[e.name] = e.value;
    if (e.value == '') {
      errors = true;
      $('#recover-form input[name=' + e.name + ']').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">هذا الحقل مطلوب.</span>')
    }
  })
  if (errors == true) {
    return false;
  }
  $.ajax({
    url: '/recover-my-password',
    type: 'POST',
    data: {
      ...dt
    },
    beforeSend: () => {
      $(this).find('input[type=submit]').attr('disabled', true).val('').addClass('loading-white')
    },
    error: (jqXHR, textStatus, errorThrown) => {
      $(this).find('input[type=submit]').attr('disabled', false).val('إعادة تعيين كلمة المرور').removeClass('loading-white')
    },
    success: (data) => {
      if (data.success) {
        $('#recover-form input[name=email]').closest('.fieldset').prepend('<span class="error-message valid">' + data.message + '</span>')
        setTimeout(function () {
          $('#recover-form input[name=email]').val('').closest('.fieldset').find('.error-message.valid').remove()
        }, 5000)
      } else {
        $('#recover-form input[name=email]').addClass('has-error').closest('.fieldset').prepend('<span class="error-message">' + data.message + '</span>')

      }
      $(this).find('input[type=submit]').attr('disabled', false).val('إعادة تعيين كلمة المرور').removeClass('loading-white')
    }
  })

})
